<template lang="html">
    <div class="container main">
        <top-navigation-bar
            title="Summarize Data"
            right-nav="reset"
            @click="doReset"
        />
        <div
            v-for="(item,index) in items"
            :key="index"
            class="input-group"
        >
            <div><label class="form-control list-id"> {{ index + 1 + "." }} </label></div>
            <input
                ref="refInput"
                v-model="item.value"
                autofocus
                class="form-control text"
                @focus="onFocus(index)"
                @keyup.enter="onSubmitEdit(item, index, $event.target)"
            >
            <a
                v-show="items.length !== 1"
                class="mt-1 ml-2"
                role="button"
                @click="removeItem(index)"
            ><i class="bi bi-dash-circle-fill" /></a>
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="btn btn-success w-25"
                @click="addItem()"
            >
                Add Row
            </button>
        </div>
        <div
            v-show="items.length === 1"
            class="my-2 p-2"
        >
            <h6 class="text-center">
                Type in the first data value then press 'enter' or 'Add Row'.
            </h6>
        </div>
        <div class="sticky-bottom m-3">
            <button
                class="action-btn btn-block"
                block
                @click="calculatePressed()"
            >
                Calculate
            </button>
        </div>

        <b-modal
            ref="refModal"
            size="lg"
            hide-footer
            title="Data Summary"
        >
            <div class="d-block text-center">
                <b-table
                    :items="[calculatedData.tableData]"
                    :fields="tableFields"
                    outlined
                    stacked
                    caption-top
                    class="tableRow"
                />
                <div>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Dot Plot">
                            <highcharts
                                class="py-1"
                                :options="dotPlotChart.chartOptions"
                            />
                        </b-tab>
                        <b-tab
                            title="Histogram Plot"
                            active
                        >
                            <highcharts
                                class="py-1"
                                :options="histogramChart.chartOptions"
                            />
                        </b-tab>
                        <b-tab title="Box Plot">
                            <highcharts
                                class="py-1"
                                :options="boxPlotChart.chartOptions"
                            />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import sData from '@/js/summarizeData/summarize-data.js'
import sampleData from '@/js/lib/sample-data.js'
import { Chart } from 'highcharts-vue'
import _ from 'lodash'

export default {
    name: 'SummarizeData',
    components: {
        TopNavigationBar,
        highcharts: Chart,
    },
    data () {
        return {
            currentID: 0,
            items: [
                { value: '' },
            ],
            calculatedData: [],
            tableFields: [
                'sampleSize',
                {
                    key: 'mean',
                    label: 'Mean (𝒙̄)'
                },
                {
                    key: 'stdDev',
                    label: 'Std Dev. (𝒔)'
                },
                'min',
                {
                    key: 'twentyFifthPercentile',
                    label: '25th Percentile',
                },
                'median',
                {
                    key: 'seventyFifthPercentile',
                    label: '75th Percentile',
                },
                'max',
                {
                    key: 'variance',
                    label: 'Variance (𝒔²)',
                },
                {
                    key: 'sum',
                    label: 'Sum (𝞢𝒙)',
                },
                'mode',
                'range'
            ],
            variants: ['danger', 'light'],
            headerBgVariant: 'danger',
            headerTextVariant: 'light',
            // Testing
            isTesting: false,
            inputDataTest: sampleData.summarizeData.case2,
        }
    },
    computed: {
        dotPlotChart () {
            return {
                chartOptions: {
                    chart: {
                        type: 'bubble',
                        borderWidth: 0,
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        data: this.calculatedData?.dotPlot,
                        sizeByAbsoluteValue: true,
                        zThreshold: -1,
                        maxSize: 15,
                        minSize: 15,
                        color: 'red',
                    }]
                }
            }
        },
        histogramChart () {
            return {
                chartOptions: {
                    legend: {
                        enabled: false
                    },
                    chart: {
                        borderWidth: 0,
                    },
                    xAxis: [{
                        alignTicks: true,
                        lineColor: 'transparent',
                        opposite: true,
                        labels: {
                            enabled: false
                        },
                    }, {
                        title: { text: 'Data' },
                    }],
                    yAxis: [{
                        alignTicks: true,
                        lineColor: 'transparent',
                        opposite: true,
                        labels: {
                            enabled: false
                        },
                    }, {
                        title: { text: 'Frequency' },
                    }],
                    series: [{
                        name: 'Histogram',
                        type: 'histogram',
                        xAxis: 1,
                        yAxis: 1,
                        baseSeries: 's1',
                        zIndex: 1,
                        color: 'red',
                    }, {
                        type: 'scatter',
                        data: this.calculatedData?.dataVals,
                        id: 's1',
                        marker: false,
                        visable: false,
                        enableMouseTracking: false
                    }]
                }
            }
        },
        boxPlotChart () {
            return {
                chartOptions: {
                    chart: {
                        type: 'boxplot',
                        inverted: true,
                        borderWidth: 0,
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    yAxis: {
                        visable: false,
                        lineWidth: 0,
                        tickLength: 0,
                        labels: {
                            enabled: false
                        },
                    },
                    series: [{
                        name: 'Observations',
                        data: [this.calculatedData.boxPlot?.output],
                        color: 'black',
                        fillColor: 'red',
                        medianColor: 'black',
                        medianWidth: 3,
                    }, {
                        type: 'scatter',
                        tooltip: {
                            enabled: false
                        },
                        dataLabels: {
                            format: '{key}',
                            enabled: true,
                            allowOverlap: true,
                            overflow: true,
                            crop: false,
                            style: {
                                fontWeight: 'bold',
                                fontSize: 14,
                            },
                        },
                        data: this.calculatedData.boxPlot?.outputLabels,
                        enableMouseTracking: false,
                        marker: {
                            enabled: false,
                        }
                    }]
                }
            }
        },
    },
    beforeMount () {
        var store = localStore.getStore('summarize-data').data
        if (store) {
            this.items = store
        }
    },

    methods: {
        doReset () {
            this.items = [{ value: '' }]
        },
        showModal () {
            this.$refs.refModal.show()
        },
        hideModal () {
            this.$refs.refModal.hide()
        },
        addItem () {
            this.items.push({ value: '' })
        },
        removeItem (index) {
            if (index !== -1) {
                this.items.splice(index, 1)
            }
            this.$refs.refInput[index].focus()
        },
        onFocus (index) {
            this.currentID = index
        },
        onSubmitEdit (item, index, event) {
            const nextRow = index + 1

            if (item.value === '') {
                return
            }
            if (nextRow !== this.items.length) {
                this.$refs.refInput[nextRow].focus()
                return
            }
            if (item.value) {
                this.addItem()
                this.$nextTick(() => {
                    this.$refs.refInput[nextRow].focus()
                })
            }
        },
        calculatePressed () {
            var data = this.items

            if (this.isTesting) {
                data = sData.formatTestData(this.inputDataTest)
            }
            var summaryData = sData.calculateDataCheck(data)

            if (!_.isEmpty(summaryData.errors)) {
                alert(_.head(summaryData.errors))
            } else {
                this.calculatedData = {
                    dataVals: sData.getDataValues(data),
                    tableData: summaryData.output,
                    dotPlot: sData.createDotDataXY(data),
                    boxPlot: sData.getBoxPlotData(summaryData.output)
                }
                this.showModal()
                localStore.setStoreItem('summarize-data', { data })
            }
        },
    }
}
</script>
