const isDev = () => {
    // -- May need to rework for local vs staging... manual toggle for now
    // var dev = false
    // if (process.env.NODE_ENV === 'development') {
    //     dev = true
    // }
    // return dev
    return false
}

const summarizeData = {
    case1: [1, 2, 2, 3, 3, 3, 3, 4, 4, 5, 6],
    case2: [0, 0, 0.0003, 0.0019, 0.0023, 0.0023, 0.0023, 0.0052, 0.0056, 0.006, 0.0072,
        0.0073, 0.0178, 0.0201, 0.021, 0.0289, 0.0294, 0.0438],
}
const hypothesis = {
    oneMean: {
        μ0: '14', // Assumed value for μ in H𝗈
        x̄: '13.3', // Sample Mean
        s: '1.88', // Sample Standard Deviation
        n: '31', // Sample Size
    },
    oneProportion: {
        p0: '0.61', // Assumed value for p in H𝗈
        x: '235', // Sample Mean
        n: '350', // Sample Size
    },
}
const regression = {
    case1: {
        x: [1, 2, 3, 4, 5],
        y: [2, 3, 3, 6, 4],
    },
    case2: {
        x: [3, 5, 7, 8, 10, 11, 17, 12, 12, 13, 15, 15, 16, 18, 19, 20],
        y: [7, 20, 20, 15, 25, 17, 20, 35, 26, 25, 35, 32, 44, 37, 45, 50],
    },
    case3: {
        x: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75],
        y: [10, 7, 15, 12, 15, 14, 20, 20, 18, 21],
    },
    case4: {
        x: [3, 5, 7, 8, 10, 11, 12, 12, 9, 10, 11, 12, 13, 14, 15],
        y: [7, 20, 20, 15, 25, 17, 20, 35, 13, 15, 15, 16, 18, 19, 20],
    },

    // -- Input Handling Tests
    // Normal
    caseError1: {
        x: [1, 2, 3, 4, 5, ''],
        y: [2, 3, 3, 6, 4, ''],
    },
    // Missing x or y
    caseError2: {
        x: [1, 2, 3, '', 5, 6],
        y: [2, 3, 3, 6, 4, ''],
    },
    // Data (< 3)
    caseError3: {
        x: [1, 2],
        y: [2, 1],
    },
    // Same x's or y's
    caseError4: {
        x: [1, 1, 1, 1, 1],
        y: [2, 2, 2, 2, 2],
    },
    // Invalid
    caseError5: {
        x: [1, 2, '/', 4, 5],
        y: ['..3', 3, 3, 6, 'w'],
    },
}

export default {
    isDev,
    summarizeData,
    hypothesis,
    regression,
}
